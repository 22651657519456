/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { PipeModal } from "~src/designSystem/deprecated/Modal/PipeModal";
import { OldButtonTryToAvoid } from "~src/designSystem/deprecated/OldButton";
import { SUPPORT_EMAIL_TEMPLATE_HREF } from "~src/shared/constants";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";

import { CodeVerificationForm } from "./CodeVerificationForm";

export const VERIFICATION_ATTEMPTS_LIMIT = 5;

export interface IVerifyRecoveryCodeModalPresentationProps {
  submitError: string | null;
  onClose: () => void;
  onSubmit: (recoveryCode: string) => Promise<void>;
}

export const VerifyRecoveryCodeModalPresentation: React.FC<IVerifyRecoveryCodeModalPresentationProps> =
  ({ submitError, onClose, onSubmit }) => {
    return (
      <PipeModal onClose={onClose}>
        <Title>Enter your recovery code</Title>
        <Instructions>Enter one of your recovery codes to access your account.</Instructions>
        <FormWrapper>
          <CodeVerificationForm
            onSubmit={onSubmit}
            footer={({ isSubmitting }) => (
              <PleaseGoUseFlexInstead oldMarginTop>
                <PleaseGoUseFlexInstead oldMarginBottom>
                  <Instructions>
                    Your recovery codes were provided to you when you set up the Two-factor
                    authentication.
                  </Instructions>
                </PleaseGoUseFlexInstead>
                {submitError !== "" && (
                  <WarningMessage
                    css={css`
                      margin: 0 0 8px 0;
                    `}
                    type="error"
                    message={submitError}
                  />
                )}
                <Footer>
                  <OldButtonTryToAvoid
                    css={css`
                      font-weight: normal;
                      font-size: 18px;
                      height: 54px;
                    `}
                    isLoading={isSubmitting}
                    type="submit"
                    size="large"
                    fullWidth
                    marginHalfTop
                  >
                    {" "}
                    Continue
                  </OldButtonTryToAvoid>
                  <RecoveryCodeCallout href={SUPPORT_EMAIL_TEMPLATE_HREF}>
                    Lost your recovery codes?
                  </RecoveryCodeCallout>
                </Footer>
              </PleaseGoUseFlexInstead>
            )}
          />
        </FormWrapper>
      </PipeModal>
    );
  };

const Footer = styled.div`
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px;
  position: absolute;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin-top: 34px;
  margin-bottom: 16px;
`;

const FormWrapper = styled.div`
  margin-top: 32px;
`;

const Instructions = styled.div`
  color: ${(props) => props.theme.components.TOTPModal.Instructions.text};
  font-size: 13px;
  line-height: 16px;
`;

const RecoveryCodeCallout = styled.a`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.components.TOTPModal.RecoveryCodeCallout.color.default};
  :hover {
    color: ${(props) => props.theme.components.TOTPModal.RecoveryCodeCallout.color.hover};
  }
  text-align: center;
  margin-top: 28px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;
