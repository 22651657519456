import { useTheme } from "@emotion/react";
import { Formik, FormikHelpers } from "formik";
import { useAnimation, usePresence } from "framer-motion";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";

import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { AuthContext } from "~src/shared/auth/AuthProvider";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import { isPipeAdminEmail } from "~src/shared/helpers";
import { useModal } from "~src/shared/modals/modal-context";
import { VerifyTotpCodeModal } from "~src/shared/multifactor/modals/VerifyTotpCodeModal";
import { logRocketIdentify } from "~src/shared/thirdParties/logrocket";
import {
  ISegmentTrackEvent,
  ISegmentTrackPage,
  useAnalytics,
} from "~src/shared/thirdParties/segment";

import { LoginPresentation } from "./index.presentation";
import { ILoginForm } from "./types";

const NUM_LOGIN_ATTEMPTS_BEFORE_LOCKOUT = 5;

export const Login: React.FC = () => {
  const router = useRouter();
  const { addAndOpenModal, clearStackAndCloseModal } = useModal();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [showLogoSpinner, setShowLogoSpinner] = useState<boolean>(false);
  const animationControl = useAnimation();
  const [isPresent, safeToRemove] = usePresence();
  const [isGoingDark, setIsGoingDark] = useState(false);
  const { login, pushRoute } = useContext(AuthContext);

  const [, setAttemptsMap] = useState<Record<string, number>>({});
  const [isLockedOut, setIsLockedOut] = useState<boolean>(false);

  const { trackEvent, trackPage } = useAnalytics();
  // Log sign-in page impression to segment
  useEffect(() => {
    trackPage(ISegmentTrackPage.SignInPageImpression);
  }, [trackPage]);

  const incrLoginAttempts = React.useCallback(
    (email: string) => {
      setAttemptsMap((oldMap) => {
        const newAttempts = (oldMap[email] ?? 0) + 1;
        if (newAttempts >= NUM_LOGIN_ATTEMPTS_BEFORE_LOCKOUT) {
          // If we are in MFA challenge, we should kill the modal now.
          clearStackAndCloseModal();
          setIsLockedOut(true);
        }
        return { ...oldMap, [email]: newAttempts };
      });
    },
    [setAttemptsMap, clearStackAndCloseModal, setIsLockedOut],
  );

  useEffect(() => {
    const id = "already-verified";
    if (router.query.used === "true") {
      toast("Email is already verified! Please login instead.", { id, duration: Infinity });
    }
    return () => {
      toast.dismiss(id);
    };
  }, [router.query.used]);

  useEffect(() => {
    (async () => {
      if (isPresent) return;
      // start the exit animation for everything
      await animationControl.start("exit");
      if (safeToRemove) {
        safeToRemove();
      }
    })();
  }, [animationControl, isPresent, safeToRemove]);

  const initialValues: ILoginForm = {
    email: "",
    password: "",
  };

  const impersonateVendorPublicID = useVendorImpersonateStore((s) => s.impersonateVendorPublicID);
  const setImpersonateVendorPublicID = useVendorImpersonateStore(
    (s) => s.setImpersonateVendorPublicID,
  );

  const onSubmit = async (values: ILoginForm, helpers: FormikHelpers<ILoginForm>) => {
    // If not a Pipe admin, call LogRocketIdentify with
    // name and email from form submission in case request fails
    if (!isPipeAdminEmail(values.email)) {
      logRocketIdentify("", values.email);
    }

    const onLoginSuccess = async (userType: string) => {
      // Otherwise login the user immediately.
      // Turn on darkmode if needed
      if (userType !== user_type_enum_enum.pipe_admin) {
        setIsGoingDark(true);
      }

      // If we logged in and are impersonating a vendor, stop and redirect to admin.
      // Only admins should trigger this condition, since only they can impersonate.
      if (impersonateVendorPublicID !== null) {
        setImpersonateVendorPublicID(null);
        window.location.assign("/admin");
      }

      setShowLogoSpinner(true);
      await animationControl.start("login");
      // Use pushRoute or else shit will fucking break half the time. We had a bug with
      // admin pages hanging after reload _some_ of the time. Why are our abstractions
      // like this? Thanos I offer you my firstborn son in exchange for better
      // authentication abstractions.
      if (userType === user_type_enum_enum.investor) {
        await pushRoute("/investor");
      } else if (userType === user_type_enum_enum.pipe_admin) {
        await pushRoute("/admin");
      } else {
        await pushRoute("/inbox");
      }
    };

    const res = await login({
      email: values.email.trim(),
      password: values.password,
    });

    if (res.ok && res.data !== null) {
      const { userType } = res.data;
      // If user has 2FA enabled, ask them to verify the TOTP code.
      if (res.data.challengeID !== undefined) {
        addAndOpenModal({
          component: (
            <VerifyTotpCodeModal
              onLoginSuccess={async () => {
                clearStackAndCloseModal();
                setShowLogoSpinner(true);
                await onLoginSuccess(userType);
              }}
              challengeID={res.data.challengeID}
              email={res.data.email}
              incrLoginAttempts={() => incrLoginAttempts(values.email)}
            />
          ),
          config: {
            isCloseButtonHidden: true,
            isCloseOnOverlayClickDisabled: true,
            width: "379px",
            zIndex: "10000",
          },
        });
      } else {
        await onLoginSuccess(userType);
      }
    } else {
      setSubmitError(res.error?.errorMessage ?? "Something went wrong.");
      helpers.setErrors(res.error?.errorFields ?? {});

      // Check whether lockout policy should be invoked.
      incrLoginAttempts(values.email);
    }
  };

  const theme = useTheme();

  // Real-time validations
  const validationSchema: yup.SchemaOf<ILoginForm> = yup.object({
    email: yup.string().trim().email().required(),
    password: yup.string().required(),
  });

  // Functions to track login page user interactions to Segment
  const trackForgotPasswordClick = () =>
    trackEvent(ISegmentTrackEvent.SignInPageForgotPasswordButtonClick);

  const trackNewAccountClick = () => trackEvent(ISegmentTrackEvent.SignInNewAccountButtonClick);

  const trackLoginButtonClick = () => trackEvent(ISegmentTrackEvent.SignInPageLoginButtonClick);

  return (
    <Formik<ILoginForm>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <LoginPresentation
        theme={theme}
        showLogoSpinner={showLogoSpinner}
        animationControl={animationControl}
        isGoingDark={isGoingDark}
        isLockedOut={isLockedOut}
        submitError={submitError}
        trackForgotPasswordClick={trackForgotPasswordClick}
        trackNewAccountClick={trackNewAccountClick}
        trackLoginButtonClick={trackLoginButtonClick}
      />
    </Formik>
  );
};
