/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { PipeModal } from "~src/designSystem/deprecated/Modal/PipeModal";
import { OldButtonTryToAvoid } from "~src/designSystem/deprecated/OldButton";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";

import { CodeVerificationForm } from "./CodeVerificationForm";

export interface IVerifyTotpCodeModalPresentationProps {
  submitError: string | null;
  onSubmit: (totpCode: string) => Promise<void>;
  onClose: () => void;
  onClickCallout: () => void;
}

export const VerifyTotpCodeModalPresentation: React.FC<IVerifyTotpCodeModalPresentationProps> = ({
  submitError,
  onSubmit,
  onClose,
  onClickCallout,
}) => {
  return (
    <PipeModal dynamicHeight onClose={onClose}>
      <Title>Two-factor authentication</Title>
      <Instructions>
        Enter the code from your Two-factor authentication application to access your account.
      </Instructions>
      <FormWrapper>
        <CodeVerificationForm
          onSubmit={onSubmit}
          footer={({ isSubmitting }) => (
            <PleaseGoUseFlexInstead oldMarginTop>
              <div style={{ minHeight: "70px" }}>
                {submitError !== "" && (
                  <WarningMessage
                    css={css`
                      margin: 0px 0px 8px 0px;
                    `}
                    type="error"
                    message={submitError}
                  />
                )}
              </div>
              <Footer>
                <OldButtonTryToAvoid
                  css={css`
                    font-weight: normal;
                    font-size: 18px;
                    height: 54px;
                  `}
                  isLoading={isSubmitting}
                  type="submit"
                  size="large"
                  fullWidth
                  marginHalfTop
                >
                  Continue
                </OldButtonTryToAvoid>
                <RecoveryCodeCallout onClick={onClickCallout}>
                  Can't access your 2FA device?
                </RecoveryCodeCallout>
              </Footer>
            </PleaseGoUseFlexInstead>
          )}
        />
      </FormWrapper>
    </PipeModal>
  );
};

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecoveryCodeCallout = styled.button`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.components.TOTPModal.RecoveryCodeCallout.color.default};
  :hover {
    color: ${(props) => props.theme.components.TOTPModal.RecoveryCodeCallout.color.hover};
  }
  text-align: center;
  margin-top: 28px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin-top: 34px;
  margin-bottom: 16px;
`;

const FormWrapper = styled.div`
  margin-top: 32px;
`;

const Instructions = styled.div`
  color: ${(props) => props.theme.components.TOTPModal.Instructions.text};
  font-size: 13px;
  line-height: 16px;
`;
